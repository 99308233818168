import React, { useCallback, useState, useRef, useEffect } from "react";
import { GoogleMap, Marker, useJsApiLoader } from "@react-google-maps/api";
import { spacedOutTextStyle } from "./spacedOutTextStyle";

const containerStyle = {
  width: "100%",
  height: "100%",
};

const ExperienceMap = ({ lat, lng }) => {
  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
    libraries: ["geometry", "drawing"],
  });

  const center = {
    lat: parseFloat(lat),
    lng: parseFloat(lng),
  };

  const [map, setMap] = useState(null);
  const mountedRef = useRef(true);

  // Add a new state for zoom level
  const [zoom, setZoom] = useState(16);

  useEffect(() => {
    return () => {
      mountedRef.current = false;
    };
  }, []);

  const onLoad = useCallback(
    function callback(map) {
      // Remove the bounds fitting
      // const bounds = new window.google.maps.LatLngBounds(center);
      // map.fitBounds(bounds);

      if (mountedRef.current) {
        setMap(map);
      }
    },
    [center],
  );

  const onUnmount = useCallback(function callback(map) {
    if (mountedRef.current) {
      setMap(null);
    }
  }, []);

  return (
    <div
      style={{
        width: "100%",
        minHeight: "292px",
      }}
    >
      <h2
        style={{
          fontSize: "28px",
          fontWeight: 600,
          ...spacedOutTextStyle,
        }}
      >
        Location
      </h2>
      {isLoaded && (
        <GoogleMap
          mapContainerStyle={containerStyle}
          center={center}
          zoom={zoom}
          onLoad={onLoad}
          onUnmount={onUnmount}
          options={{
            fullscreenControl: true,
            mapTypeControl: false,
            streetViewControl: false,
            zoomControl: false,
          }}
        >
          <Marker position={center} />
        </GoogleMap>
      )}
    </div>
  );
};

export default ExperienceMap;
