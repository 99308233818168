import { handleError } from "./appDucks";
import { createSearchUrl, getDiscoveryUrl } from "../constants/url";

const initialState = {
  discoveryList: [],
  filteredExperiences: null,
  filteringQuery: null,
  loading: false,
  loadingMore: false,
  error: false,
  nextPageToken: null,
  hasMoreSearchResults: false,
  nextPageNumber: null,
  scrollPosition: null,
};

const GET_DISCOVERY_SUCCESS = "GET_DISCOVERY_SUCCESS";
const PAGE_SIZE = 20;
const SEARCH_EXPERIENCES_SUCCESS = "SEARCH_EXPERIENCES_SUCCESS";
const START_LOADING = "START_LOADING";
const STOP_LOADING = "STOP_LOADING";
const START_LOADING_MORE = "START_LOADING_MORE";
const STOP_LOADING_MORE = "STOP_LOADING_MORE";
const RESET_FILTERED_EXPERIENCES = "RESET_FILTERED_EXPERIENCES";
const UPDATE_FILTERING_QUERY = "UPDATE_FILTERING_QUERY";
const UPDATE_SCROLL_POSITION = "UPDATE_SCROLL_POSITION";

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case START_LOADING:
      return { ...state, loading: true };
    case STOP_LOADING:
      return { ...state, loading: false };
    case START_LOADING_MORE:
      return { ...state, loadingMore: true };
    case STOP_LOADING_MORE:
      return { ...state, loadingMore: false };
    case GET_DISCOVERY_SUCCESS:
      if (state.discoveryList?.items?.length > 0)
        return {
          ...state,
          discoveryList: {
            items: [...state.discoveryList.items, ...action.payload.items],
          },
          nextPageToken: action.payload.next_page_token,
        };
      return {
        ...state,
        discoveryList: action.payload,
        nextPageToken: action.payload.next_page_token,
      };
    case SEARCH_EXPERIENCES_SUCCESS:
      let newUniqueEntries = action.payload.entries;
      if (state.filteredExperiences?.length > 0) {
        newUniqueEntries = [...state.filteredExperiences, ...newUniqueEntries];
      }
      return {
        ...state,
        filteredExperiences: newUniqueEntries,
        nextPageNumber: action.payload?.paginate?.page,
        hasMoreSearchResults: action.payload?.entries?.length > 0,
      };
    case RESET_FILTERED_EXPERIENCES:
      return {
        ...state,
        filteringQuery: null,
        filteredExperiences: null,
        hasMoreSearchResults: false,
        nextPageNumber: null,
        scrollPosition: null,
      };
    case UPDATE_SCROLL_POSITION:
      return {
        ...state,
        scrollPosition: action.payload,
      };
    case UPDATE_FILTERING_QUERY:
      return {
        ...state,
        filteringQuery: action.payload,
      };
    default:
      return state;
  }
}

export const getDiscovery = () => async (dispatch) => {
  dispatch({ type: START_LOADING });
  try {
    const result = await fetchExperiences();
    if (result) {
      dispatch({
        type: GET_DISCOVERY_SUCCESS,
        payload: result,
      });
    }
  } catch (error) {
    dispatch(handleError(error));
  } finally {
    dispatch({ type: STOP_LOADING });
  }
};

export const getMoreDiscovery =
  (pageToken = null) =>
  async (dispatch) => {
    dispatch({ type: START_LOADING_MORE });
    try {
      const result = await fetchExperiences(pageToken);
      if (result) {
        dispatch({
          type: GET_DISCOVERY_SUCCESS,
          payload: result,
        });
      }
    } catch (error) {
      dispatch(handleError(error));
    } finally {
      dispatch({ type: STOP_LOADING_MORE });
    }
  };

export const getMoreSearchResults =
  (query, page = 1) =>
  async (dispatch) => {
    dispatch({ type: START_LOADING_MORE });
    try {
      const myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: JSON.stringify({
          q: query,
          page: page,
          per_page: PAGE_SIZE,
        }),
        redirect: "follow",
      };

      const fetchResponse = await fetch(createSearchUrl, requestOptions);
      const result = await fetchResponse.text();

      let response = {};
      try {
        response = JSON.parse(result);
        dispatch({
          type: SEARCH_EXPERIENCES_SUCCESS,
          payload: response,
        });
      } catch (error) {
        console.error(`Failed to parse result from API. Error: ${error}`);
      }
    } catch (error) {
      dispatch(handleError(error));
    } finally {
      dispatch({ type: STOP_LOADING_MORE });
    }
  };

export const searchExperiences = (query) => async (dispatch) => {
  dispatch({ type: START_LOADING });
  dispatch({ type: UPDATE_FILTERING_QUERY, payload: query });
  try {
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: JSON.stringify({
        q: query,
        page: 1,
        per_page: 20,
      }),
      redirect: "follow",
    };

    const fetchResponse = await fetch(createSearchUrl, requestOptions);
    const result = await fetchResponse.text();

    let response = {};
    try {
      response = JSON.parse(result);
      dispatch({
        type: SEARCH_EXPERIENCES_SUCCESS,
        payload: {
          ...response,
        },
      });
    } catch (error) {
      console.error(`Failed to parse result from API. Error: ${error}`);
    }
  } catch (error) {
    dispatch(handleError(error));
  } finally {
    dispatch({ type: STOP_LOADING });
  }
};

const fetchExperiences = async (pageToken = null) => {
  const myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");

  const raw = pageToken
    ? JSON.stringify({
        page_size: 5,
        page_token: pageToken,
      })
    : JSON.stringify({
        page_size: PAGE_SIZE,
      });

  const requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: raw,
    redirect: "follow",
  };

  const fetchResponse = await fetch(getDiscoveryUrl, requestOptions);
  const result = await fetchResponse.text();

  let response = {};
  try {
    response = JSON.parse(result);
  } catch (error) {
    console.error(`Failed to parse result from API. Error: ${error}`);
  }

  return response;
};

export const resetFilteredExperiences = () => ({
  type: RESET_FILTERED_EXPERIENCES,
});

export const updateScrollPosition = (position) => ({
  type: UPDATE_SCROLL_POSITION,
  payload: position,
});
