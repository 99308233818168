import React, { lazy, Suspense } from "react";
import { spacedOutTextStyle } from "./spacedOutTextStyle";

const LazyExperiences = lazy(() => import("../../../components/Experiences"));

const RelatedExperiences = ({ experience, loading }) => {
  if (loading) {
    return <div>Loading related experiences...</div>;
  }

  return (
    <>
      <div style={{ marginTop: "60px" }}>
        <h2
          style={{
            fontSize: "28px",
            fontWeight: 600,
            ...spacedOutTextStyle,
          }}
        >
          Related Experiences
        </h2>
        <Suspense fallback={<div>Loading related experiences...</div>}>
          <LazyExperiences
            cardsData={experience?.related_ids}
            size="sm-spaced"
            cardsToShowInitially={3}
            cardsToSlide={1}
          />
        </Suspense>
      </div>

      <div>
        <h2
          style={{
            fontSize: "28px",
            fontWeight: 600,
            marginBottom: "10px",
            ...spacedOutTextStyle,
          }}
        >
          Nearby Experiences
        </h2>
        <Suspense fallback={<div>Loading nearby experiences...</div>}>
          <LazyExperiences
            cardsData={experience?.nearby_ids}
            size="sm-spaced"
            cardsToShowInitially={3}
            cardsToSlide={1}
          />
        </Suspense>
      </div>
    </>
  );
};

export default RelatedExperiences;
