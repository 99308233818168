import { handleError } from "./appDucks";
import { getSearchSearchableTermsUrl } from "../constants/url";

const INITIAL_STATE = {
  searchableTerms: [],
  loading: false,
};

const GET_SEARCHABLE_TERMS_SUCCESS = "GET_SEARCHABLE_TERMS_SUCCESS";
const GET_SEARCHABLE_TERMS_START_LOADING = "GET_SEARCHABLE_TERMS_START_LOADING";
const GET_SEARCHABLE_TERMS_STOP_LOADING = "GET_SEARCHABLE_TERMS_STOP_LOADING";

export default function reducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case GET_SEARCHABLE_TERMS_SUCCESS:
      return { ...state, searchableTerms: action.payload };
    case GET_SEARCHABLE_TERMS_START_LOADING:
      return { ...state, loading: true };
    case GET_SEARCHABLE_TERMS_STOP_LOADING:
      return { ...state, loading: false };
    default:
      return state;
  }
}

export const getSearchableTerms = () => async (dispatch) => {
  dispatch({ type: GET_SEARCHABLE_TERMS_START_LOADING });
  try {
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    const requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    const fetchResponse = await fetch(getSearchSearchableTermsUrl, requestOptions);
    const result = await fetchResponse.text();

    let response = {};
    try {
      response = JSON.parse(result);
      dispatch({
        type: GET_SEARCHABLE_TERMS_SUCCESS,
        payload: response,
      });
    } catch (error) {
      console.error(`Failed to parse result from API. Error: ${error}`);
    }
  } catch (error) {
    dispatch(handleError(error));
  } finally {
    dispatch({ type: GET_SEARCHABLE_TERMS_STOP_LOADING });
  }
};
