import React from "react";
import { ReactComponent as LikeIcon } from "../../../assets/Icons/like-icon.svg";
import { ReactComponent as ClockIcon } from "../../../assets/Icons/clock-icon.svg"; // Assuming you have a clock icon
import { spacedOutTextStyle } from "./spacedOutTextStyle";

const ExperienceHeader = ({ experience }) => (
  <div
    style={{
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      alignItems: "center",
    }}
  >
    <div style={{ marginBottom: "20px", width: "80%" }}>
      <h1
        style={{
          color: "var(--green-color)",
          fontSize: "36px",
          fontWeight: 600,
          marginBottom: "10px",
          ...spacedOutTextStyle,
        }}
      >
        {experience.name || "Name not available"}
      </h1>
      <p
        style={{
          marginBottom: "20px",
          color: "var(--secondary-text-color)",
          ...spacedOutTextStyle,
        }}
      >
        {experience.location || "Location not available"}
      </p>

      <div
        style={{
          display: "flex",
          flexDirection: "row",
          flexWrap: "wrap",
          gap: "10px",
          width: "100%",
        }}
      >
        {experience?.topics?.map((topic) => (
          <span
            key={topic}
            style={{
              backgroundColor: "var(--pink-color)",
              color: "white",
              padding: "0.5rem 1rem",
              borderRadius: "5px",
              height: "auto",
              width: "auto",
              display: "inline-flex",
              alignItems: "center",
              justifyContent: "center",
              marginBottom: "5px",
            }}
          >
            {topic}
          </span>
        ))}
      </div>
    </div>
    <div
      style={{
        display: "flex",
        alignItems: "right",
        gap: "20px",
        flexDirection: "column",
      }}
    >
      <div style={{ display: "flex" }}>
        <LikeIcon style={{ marginRight: "10px" }} />
        <p
          style={{
            color: "var(--pink-color)",
            display: "flex",
            ...spacedOutTextStyle,
          }}
        >
          {experience.likes}
        </p>
      </div>

      <div style={{ display: "flex" }}>
        <p
          style={{
            color: "var(--pink-color)",
            display: "flex",
            ...spacedOutTextStyle,
          }}
        >
          {/* Stay Time: {experience.stay_in_minutes} min */}
          <ClockIcon style={{ marginRight: "10px" }} />
          {experience.stay_in_minutes} min
        </p>
      </div>
    </div>
  </div>
);

export default ExperienceHeader;
