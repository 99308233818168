import React from "react";
import HeroHeading from "./Heading/Heading";
import Slick from "../Slick/Slick";
import "./Experiences.css";
import Card from "../Card/Card";

const Experiences = ({
  title,
  innerDivKey = Math.random(1000),
  cardsData,
  cardsToShowInitially,
  cardsToSlide,
  size,
  useSlick = true,
}) => {
  const getContainerStyle = () => {
    switch (size) {
      case "lg":
        return {
          width: "80%",
          margin: "0 auto",
        };
      case "sm":
        return {
          width: "78%",
          margin: "0 auto",
        };
      case "sm-spaced":
        return {
          width: "95%",
          margin: "0 auto",
        };
      default:
        return {
          width: "78%",
          margin: "0 auto",
        };
    }
  };

  const getContainerClassName = () => {
    return size === "lg" ? "main-row-container" : "experience-row-container";
  };

  const renderCards = () => {
    if (useSlick) {
      return (
        <Slick slidesToShow={cardsToShowInitially} slidesToScroll={cardsToSlide} arrows={useSlick}>
          {cardsData?.map((item, index) => (
            <Card
              _id={item.experience_id || item.id}
              title={item.name}
              image={item.images[0]}
              subTitle={item.destination}
              key={index}
              size={size}
            />
          ))}
        </Slick>
      );
    }

    return (
      <div className="experiences-grid">
        {cardsData?.map((item, index) => (
          <Card
            _id={item.experience_id || item.id}
            title={item.name}
            image={item.images[0]}
            subTitle={item.destination}
            key={index}
            size={size}
          />
        ))}
      </div>
    );
  };

  return (
    <div style={getContainerStyle()} className={getContainerClassName()} key={innerDivKey}>
      <HeroHeading title={title} size={size} />
      <div
        style={
          size === "sm" || size === "sm-spaced"
            ? {
                margin: "10px 0",
              }
            : {}
        }
      >
        {renderCards()}
      </div>
    </div>
  );
};

export default Experiences;
