import React from "react";
import { featureIcons } from "../../../assets/Icons/features-icons";
import { spacedOutTextStyle } from "./spacedOutTextStyle";

export const renderFeatures = (experience) => {
  const facilities = experience?.facilities;
  if (!facilities) return null;

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        padding: "20px",
      }}
    >
      <div
        style={{
          width: "100%",
        }}
      >
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "repeat(auto-fill, minmax(180px, 1fr))",
            gap: "20px",
            width: "100%",
          }}
        >
          {Object.entries(facilities).map(([key, value]) => {
            if (value && featureIcons[key]) {
              const IconComponent = featureIcons[key];
              return (
                <div
                  key={key}
                  style={{
                    borderRadius: "15px",
                    padding: "20px",
                    boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
                    display: "flex",
                    alignItems: "center",
                    gap: "10px",
                    ...spacedOutTextStyle,
                  }}
                >
                  <IconComponent className="h-6 w-6" />
                  <span style={{ textTransform: "capitalize" }}>{key.replace(/_/g, " ")}</span>
                </div>
              );
            }
            return null;
          })}
        </div>
      </div>
    </div>
  );
};
