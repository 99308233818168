import React, { useEffect, useState } from "react";
import { Gallery } from "react-grid-gallery";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";
import "./ExperienceGallery.css";

const ExperienceGallery = ({ images }) => {
  const [index, setIndex] = useState(-1);

  const [galleryImages, setGalleryImages] = useState([]);
  const [containerWidth, setContainerWidth] = useState(0);

  const [margin, setMargin] = useState(4);
  const [rowHeight, setRowHeight] = useState(400);

  useEffect(() => {
    // Get the container width
    const container = document.querySelector(".experience-gallery-container");
    if (container) {
      setContainerWidth(container.offsetWidth);
    }
  }, []);

  useEffect(() => {
    if (containerWidth > 0 && images) {
      setGalleryImages(calculateImageDimensions(images, containerWidth));
    }
  }, [containerWidth, images]);

  const calculateImageDimensions = (images, totalWidth) => {
    const minWidth = 300;
    const adjustedWidth = totalWidth - margin * (images.length - 1);

    const getRandomDimensions = (remainingWidth, remainingImages) => {
      if (remainingImages === 1) return Math.max(remainingWidth, minWidth);
      const maxWidth = Math.min(Math.floor(remainingWidth * 0.6), remainingWidth - (remainingImages - 1) * minWidth);
      return Math.floor(Math.random() * (maxWidth - minWidth + 1) + minWidth);
    };

    const assignDimensions = (imagesToAssign, rowWidth) => {
      let remaining = rowWidth;
      return imagesToAssign.map((image, index) => {
        const width =
          index === imagesToAssign.length - 1
            ? Math.max(remaining, minWidth)
            : getRandomDimensions(remaining, imagesToAssign.length - index);
        remaining -= width;
        return {
          src: image,
          width,
          height: rowHeight,
          caption: "",
        };
      });
    };

    const maxImagesPerRow = Math.floor(adjustedWidth / minWidth);

    if (images.length <= maxImagesPerRow) {
      return assignDimensions(images, adjustedWidth);
    } else {
      const firstRow = assignDimensions(images.slice(0, maxImagesPerRow), adjustedWidth);
      const secondRow = assignDimensions(images.slice(maxImagesPerRow, maxImagesPerRow * 2), adjustedWidth);
      return [...firstRow, ...secondRow];
    }
  };

  const nextIndex = (index + 1) % galleryImages.length;
  const prevIndex = (index + galleryImages.length - 1) % galleryImages.length;

  const handleClick = (index, item) => {
    document.body.removeAttribute("aria-hidden");
    setIndex(index);
  };

  const handleClose = () => {
    document.body.removeAttribute("aria-hidden");
    setIndex(-1);
  };

  const handleMovePrev = () => setIndex(prevIndex);
  const handleMoveNext = () => setIndex(nextIndex);

  useEffect(() => {
    return () => {
      document.body.removeAttribute("aria-hidden");
    };
  }, []);

  return (
    <div className="experience-gallery-container">
      <Gallery
        images={galleryImages}
        enableImageSelection={false}
        rowHeight={rowHeight}
        margin={margin}
        onClick={handleClick}
      />
      {index >= 0 && (
        <Lightbox
          mainSrc={galleryImages[index].src}
          nextSrc={galleryImages[nextIndex]?.src}
          prevSrc={galleryImages[prevIndex]?.src}
          onCloseRequest={handleClose}
          onMovePrevRequest={handleMovePrev}
          onMoveNextRequest={handleMoveNext}
          enableZoom={true}
          animationDuration={300}
          clickOutsideToClose={true}
          discourageDownloads={false}
          imagePadding={10}
        />
      )}
    </div>
  );
};

export default ExperienceGallery;
