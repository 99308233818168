import { useCallback, useEffect, useState } from "react";
import {
  getDiscovery,
  getMoreDiscovery,
  getMoreSearchResults,
  resetFilteredExperiences,
  searchExperiences,
  updateScrollPosition,
} from "../../../redux/discoveryDucks";
import { useDispatch, useSelector } from "react-redux";
import { filterAllTopics, filterByTopicLength, getExperiencesFromData, getRandomArray } from "../../../utils/array";

const useDiscovery = () => {
  const dispatch = useDispatch();
  const discoveryData = useSelector((state) => state.discovery);
  const { searchableTerms } = useSelector((state) => state.searchableTerms);

  const [recommendedExperiences, setRecommendedExperiences] = useState([]);
  const filteringQuery = useSelector((state) => state.discovery.filteringQuery);
  const [filteredData, setFilteredData] = useState(null);
  const scrollPosition = useSelector((state) => state.discovery.scrollPosition);

  // Configuration states
  const [numberOfExperiencesInHero, setnumberOfExperiencesInHero] = useState(3);
  const [cardsToSlideInHero, setcardsToSlideInHero] = useState(1);
  const [totalNumberOfExperiencesInHero, setTotalNumberOfExperiencesInHero] = useState(6);
  const [minimumLengthOfTopics, setMinimumLengthOfTopics] = useState(2);

  // Row Settings
  const [cardsToShowInitially, setcardsToShowInitially] = useState(5);
  const [cardsToSlide, setcardsToSlide] = useState(1);

  const [allTopics, setAllTopics] = useState([]);
  const [topicsWithMinimumLength, setTopicsWithMinimumLength] = useState([]);

  useEffect(() => {
    if (!discoveryData?.discoveryList) {
      return;
    }
    let allTopics = filterAllTopics(discoveryData?.discoveryList);
    setAllTopics(allTopics);
    setTopicsWithMinimumLength(filterByTopicLength(allTopics, minimumLengthOfTopics));
  }, [discoveryData?.discoveryList]);

  const handleScroll = () => {
    dispatch(updateScrollPosition(window.scrollY));
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    if (discoveryData?.discoveryList || discoveryData?.filteredExperiences) {
      restoreScrollPosition();
      return;
    }
    dispatch(getDiscovery());
  }, []);

  useEffect(() => {
    if (recommendedExperiences.length !== 0) return;

    setRecommendedExperiences(getRandomArray(getExperiencesFromData(allTopics), totalNumberOfExperiencesInHero));
  }, [discoveryData]);

  useEffect(() => {
    if (!discoveryData?.filteredExperiences) {
      setFilteredData(null);
      return;
    }
    setFilteredData({
      name: filteringQuery,
      experiences: discoveryData?.filteredExperiences,
    });
  }, [filteringQuery, discoveryData?.filteredExperiences]);

  const handleSearch = useCallback(
    (searchValue) => {
      if (!searchValue) {
        handleReset();
        return;
      }
      dispatch(resetFilteredExperiences());
      dispatch(searchExperiences(searchValue));
    },
    [dispatch],
  );

  const restoreScrollPosition = () => {
    if (!scrollPosition) {
      return;
    }
    setTimeout(() => {
      window.scrollTo({
        top: scrollPosition,
        behavior: "instant",
      });
    }, 0);
  };

  const handleReset = () => {
    dispatch(resetFilteredExperiences());
  };

  const loadMoreDiscovery = () => {
    if (discoveryData.loadingMore) return;

    const { nextPageToken } = discoveryData;
    if (nextPageToken) {
      dispatch(getMoreDiscovery(nextPageToken));
    }
  };

  const loadMoreSearchResults = () => {
    if (discoveryData.loadingMore || !filteringQuery || !discoveryData?.hasMoreSearchResults) return;

    dispatch(getMoreSearchResults(filteringQuery, discoveryData.nextPageNumber));
  };

  return {
    // States
    recommendedExperiences,
    filteringQuery,
    filteredData,
    // Configuration
    numberOfExperiencesInHero,
    totalNumberOfExperiencesInHero,
    cardsToSlideInHero,
    cardsToShowInitially,
    cardsToSlide,
    minimumLengthOfTopics,
    // Data
    topicsWithMinimumLength,
    searchableTerms,
    discoveryData,
    // Functions
    handleSearch,
    handleReset,
    loadMoreDiscovery,
    loadMoreSearchResults,
  };
};

export default useDiscovery;
