import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getDiscoveryExperience } from "../../../redux/experienceDucks";

const useExperience = (id) => {
  const dispatch = useDispatch();
  const { experience, loading } = useSelector((state) => state.experiences);

  var website_url = experience?.website_url;
  if (website_url === "-") {
    website_url = null;
  }

  const cleanedExperience = {
    images: experience?.images?.map((image) => image.picture_url),
    name: experience?.name,
    latitude: experience?.latitude,
    longitude: experience?.longitude,
    location: experience?.destination?.name,
    description: experience?.description,
    contact: {
      // name: experience?.name,
      phone_number: experience?.phone_number,
      email: experience?.email,
      website_url: website_url,
      facebook_url: experience?.facebook_url,
      instagram_url: experience?.instagram_url,
      whats_app: experience?.whats_app,
    },
    facilities: {
      adults_only: experience?.adults_only,
      bird_watching: experience?.bird_watching,
      camping: experience?.camping,
      carbon_neutral: experience?.carbon_neutral,
      ev_charger: experience?.ev_charger,
      food_drinks: experience?.food_drinks,
      internet: experience?.internet,
      parking: experience?.parking,
      pet_friendly: experience?.pet_friendly,
      public_transport: experience?.public_transport,
      security_lockers: experience?.security_lockers,
      smoking_area: experience?.smoking_area,
      wheelchair_accessible: experience?.wheelchair_accessible,
    },
    likes: experience?.likes,
    stay_in_minutes: experience?.stay_in_minutes,
    timetables: experience?.timetables,
    topics: experience?.topics,
    related_ids: experience?.related_ids,
    nearby_ids: experience?.nearby_ids,
  };

  useEffect(() => {
    dispatch(getDiscoveryExperience(id));
  }, [dispatch, id]);

  return { experience: cleanedExperience, loading };
};

export default useExperience;
