import React from "react";
import { spacedOutTextStyle } from "./spacedOutTextStyle";

export const renderTimetables = (experience) => {
  if (!experience?.timetables || experience?.timetables.length === 0) return null;

  const daysOfWeek = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"];

  // Adjust the rendering to match the new order
  const orderedTimetables = daysOfWeek.map(
    (_, index) =>
      experience?.timetables.find((timetable) => timetable.wday === index) || {
        id: index,
        wday: index,
        open_hour: "Closed",
        close_hour: "Closed",
      },
  );

  return (
    <div
      style={{
        border: "2px solid var(--principal-color-page)",
        borderRadius: "8px",
        padding: "20px",
        ...spacedOutTextStyle,
      }}
    >
      <h3
        style={{
          fontWeight: 600,
          marginBottom: "20px",
          textAlign: "center",
          ...spacedOutTextStyle,
        }}
      >
        Opening Hours
      </h3>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "10px",
          width: "95%",
          maxWidth: "350px",
          margin: "0 auto",
          ...spacedOutTextStyle,
        }}
      >
        {orderedTimetables.map((timetable) => (
          <div
            key={timetable.id}
            style={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <span style={{ fontWeight: 600 }}>{daysOfWeek[timetable.wday]}</span>
            <span>
              {timetable.open_hour === "Closed"
                ? timetable.open_hour
                : `${timetable.open_hour} - ${timetable.close_hour}`}
            </span>
          </div>
        ))}
      </div>
    </div>
  );
};
