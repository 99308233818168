const filterAllTopics = (data = []) => {
  return data?.items?.filter((item) => item["@type"] === "topic");
};

const filterByTopicLength = (data, length) => {
  return data?.filter((item) => item?.experiences?.length >= length);
};

const shuffleArray = (array) => {
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [array[i], array[j]] = [array[j], array[i]];
  }
  return array;
};

function getExperiencesFromData(data = []) {
  return data?.flatMap((topic) => topic.experiences);
}

function getRandomArray(array, length) {
  const randomArray = shuffleArray(array);

  return randomArray.slice(0, length);
}

export { filterAllTopics, shuffleArray, getRandomArray, getExperiencesFromData, filterByTopicLength };
