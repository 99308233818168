import React from "react";
import Header from "../../components/Header";
import Experiences from "../../components/Experiences";
import useDiscovery from "./hooks/useDiscovery";
import InfiniteScroll from "react-infinite-scroller";
import InfiniteScrollLoader from "../../components/InfiniteScrollLoader";

const Discovery = () => {
  const {
    recommendedExperiences,
    filteringQuery,
    filteredData,
    numberOfExperiencesInHero,
    totalNumberOfExperiencesInHero,
    cardsToSlideInHero,
    cardsToShowInitially,
    cardsToSlide,
    topicsWithMinimumLength,
    searchableTerms,
    discoveryData,
    handleSearch,
    handleReset,
    loadMoreDiscovery,
    loadMoreSearchResults,
  } = useDiscovery();

  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        minHeight: "calc(100vh - 50px)",
        backgroundColor: "var(--background-color)",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
      }}
    >
      <Header
        searchQuery={filteringQuery}
        searchableTerms={searchableTerms}
        onSearch={handleSearch}
        onReset={handleReset}
        showReset={!!filteredData}
      />

      <div
        style={{
          width: "100%",
          height: "100%",
          maxWidth: "1500px",
          minHeight: "80vh",
          margin: "0 auto",
          marginTop: "50px",
          gap: "50px",
          display: "flex",
          flexDirection: "column",
        }}
      >
        {!filteredData && (
          <Experiences
            title="Recommended for you"
            cardsData={recommendedExperiences}
            cardsToShowInitially={numberOfExperiencesInHero}
            cardsToSlide={cardsToSlideInHero}
            size="lg"
          />
        )}

        <div
          style={{
            padding: "0px",
            width: "100%",
            position: "relative",
          }}
        >
          <InfiniteScroll
            pageStart={0}
            loadMore={filteredData ? loadMoreSearchResults : loadMoreDiscovery}
            hasMore={filteredData ? !!discoveryData?.hasMoreSearchResults : !!discoveryData?.nextPageToken}
            loader={<InfiniteScrollLoader key="infinite-scroll-loader" />}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "10px",
              }}
            >
              {filteredData && (
                <Experiences
                  title={filteredData?.name}
                  cardsData={filteredData?.experiences}
                  key={"Experience_" + filteredData?.topic_id}
                  innerDivKey={"Experience_Inner_Div_" + filteredData?.topic_id}
                  size="sm"
                  useSlick={false}
                />
              )}
              {!filteredData &&
                topicsWithMinimumLength?.map((item, index) => (
                  <Experiences
                    title={item?.name}
                    cardsData={item?.experiences}
                    key={"Experience_" + item?.topic_id + index}
                    innerDivKey={"Experience_Inner_Div_" + item?.topic_id + index}
                    cardsToShowInitially={cardsToShowInitially}
                    cardsToSlide={cardsToSlide}
                    size="sm"
                  />
                ))}
            </div>
          </InfiniteScroll>
        </div>
      </div>
    </div>
  );
};

export default Discovery;
