import { applyMiddleware, combineReducers, compose, createStore } from "redux";
import thunk from "redux-thunk";
import experienceReducer from "./experienceDucks";
import appReducer from "./appDucks";
import discoveryReducer, { getDiscovery } from "./discoveryDucks";
import searchableTermsReducer, { getSearchableTerms } from "./searchableTermsDucks";

const STORE_RESET_STORE = "STORE_RESET_STORE";

export const createRootReducer = (state, action) => {
  if (action.type === STORE_RESET_STORE) {
    // To invalidate the local session.
    localStorage.clear();
  }
  return combineReducers({
    app: appReducer,
    experiences: experienceReducer,
    discovery: discoveryReducer,
    searchableTerms: searchableTermsReducer,
  })(state, action);
};

export const resetStore = () => ({
  type: STORE_RESET_STORE,
});

let store = null;

export default function setUpStore(preloadedState = {}) {
  store = createStore(createRootReducer, preloadedState, compose(applyMiddleware(thunk)));
  store.dispatch(getDiscovery());
  store.dispatch(getSearchableTerms());
  return store;
}
