import { Card, Image } from "semantic-ui-react";
import React from "react";
import "./Card.css";
import { Link } from "react-router-dom";
import * as ROUTES from "../../constants/routes";

const CardComponent = ({ _id = Math.random(1000), image, subTitle, title, size, lazyLoad = false }) => {
  const cardHeaderStyle = {
    color: "var(--primary-text-color)",
    fontFamily: "var(--font-family-poppins)",
    fontSize: size === "lg" ? "18px" : "16px",
  };

  return (
    <Link to={`${ROUTES.EXPERIENCES}/${_id}`} className="block">
      <Card
        style={
          size === "lg"
            ? {
                margin: "0 10px",
                boxShadow: "none",
                background: "none",
                width: "auto",
              }
            : {
                background: "none",
                boxShadow: "none",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }
        }
        className={size === "lg" ? "main-card-container" : "card-container"}
        key={_id}
      >
        <div
          style={
            size === "lg"
              ? {}
              : {
                  width: "90%",
                }
          }
        >
          <div className={size === "lg" ? "main-card-image" : "card-image"}>
            <Image src={image} wrapped ui={false} loading={lazyLoad ? "lazy" : "eager"} />
          </div>
          <Card
            style={{
              backgroundColor: "var(--background-color)",
              padding: "10px 0",
              boxShadow: "none",
            }}
          >
            <Card.Header style={cardHeaderStyle} className="font-thin">
              {title}
            </Card.Header>
            <Card.Meta
              style={{
                color: "var(--secondary-text-color)",
                fontFamily: "var(--font-family-poppins)",
                fontSize: "14px",
                paddingTop: "2px",
              }}
            >
              {subTitle}
            </Card.Meta>
          </Card>
        </div>
      </Card>
    </Link>
  );
};

export default CardComponent;
