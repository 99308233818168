import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Loading from "./components/Loading";
import * as ROUTES from "./constants/routes";
import "./App.css";
import { Helmet } from "react-helmet";
import { AppMessage } from "./components/AppMessage";
import Discovery from "./views/Discovery";
import Experience from "./views/Experience";

const App = () => {
  const [initialScale, setInitialScale] = useState(1.0);

  useEffect(() => {
    if (navigator.userAgent.match(/Android/i) || navigator.userAgent.match(/iPhone|iPad/i)) {
      setInitialScale(0.1);
    }
  }, []);

  return (
    <>
      <Helmet>
        <meta name="viewport" content={`width=1280, initial-scale=${initialScale}, maximum-scale=1.0`} />
      </Helmet>
      <Loading />
      <AppMessage />
      <Router>
        <Switch>
          <Route exact path={ROUTES.ROOT} component={Discovery} />
          <Route exact path={`${ROUTES.EXPERIENCES}/:id`} component={Experience} />
        </Switch>
      </Router>
    </>
  );
};

export default App;
