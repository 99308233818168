import React from "react";
import { useParams } from "react-router-dom";
import Header from "../../components/Header";
import useExperience from "./hooks/useExperience";
import ExperienceHeader from "./components/ExperienceHeader";
import ExperienceContent from "./components/ExperienceContent";

const Experience = () => {
  const { id } = useParams();
  const { experience, loading } = useExperience(id);

  if (loading) {
    return <></>;
  }

  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        minHeight: "calc(100vh - 50px)",
        backgroundColor: "var(--background-color)",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        gap: "50px",
      }}
    >
      <Header />

      <div
        style={{
          width: "100%",
          height: "100%",
          maxWidth: "1500px",
          minHeight: "800px",
          margin: "0 auto",
          gap: "50px",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <main
          style={{
            width: "100%",
            height: "100%",
            maxWidth: "1140px",
            minHeight: "800px",
            margin: "0 auto",
            padding: "20px",
          }}
        >
          <ExperienceHeader experience={experience} />
          <ExperienceContent experience={experience} loading={loading} />
        </main>
      </div>
    </div>
  );
};

export default Experience;
