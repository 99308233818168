import React from "react";
import { Loader } from "semantic-ui-react";

const InfiniteScrollLoader = () => {
  return (
    <div
      style={{
        width: "100%",
        textAlign: "center",
        padding: "20px 0",
        display: "flex",
        justifyContent: "center",
      }}
    >
      <Loader active inline="centered">
        Loading more...
      </Loader>
    </div>
  );
};

export default InfiniteScrollLoader;
