import React from "react";
import { Header } from "semantic-ui-react";

const Heading = ({ title, size }) => {
  return (
    <Header
      style={{
        color: "var(--green-color)",
        fontSize: size === "lg" ? "30px" : "25px",
        fontWeight: 600,
        padding: "0 0 30px 0",
      }}
    >
      {title}
    </Header>
  );
};

export default Heading;
