import { getExperienceUrl } from "../constants/url";
import { handleError } from "./appDucks";

const INITIAL_STATE = {
  experience: null,
  experiences: null,
  filterInReview: false,
  filterQuery: "",
  loading: false,
  pageIndex: null,
  pageSize: 200,
  pageToken: null,
  suggestExperienceTitles: null,
  suggestPageSize: 10,
};

const ACTION_TYPES = {
  EXPERIENCES_PATCH_STATE: "EXPERIENCES_PATCH_STATE",
};

export default function reducer(state = INITIAL_STATE, action) {
  if (action.type !== ACTION_TYPES.EXPERIENCES_PATCH_STATE) {
    return state;
  }
  return {
    ...state,
    ...action.payload,
  };
}

export const getDiscoveryExperience = (id) => async (dispatch) => {
  dispatch(startLoading());
  try {
    const url = getExperienceUrl.replace(":experience_id", id);

    const headers = new Headers();
    headers.append("Content-Type", "application/json");

    const options = {
      method: "GET",
      headers: headers,
      redirect: "follow",
    };

    const response = await fetch(url, options);
    const result = await response.text();

    let experience = {};
    try {
      experience = JSON.parse(result);
    } catch (error) {
      dispatch(handleError(error));
      return;
    }
    dispatch({
      type: ACTION_TYPES.EXPERIENCES_PATCH_STATE,
      payload: { experience: experience },
    });
  } catch (error) {
    dispatch(handleError(error));
  } finally {
    dispatch(stopLoading());
  }
};

const startLoading = () => async (dispatch) => {
  dispatch({
    type: ACTION_TYPES.EXPERIENCES_PATCH_STATE,
    payload: { loading: true },
  });
};

const stopLoading = () => async (dispatch) => {
  dispatch({
    type: ACTION_TYPES.EXPERIENCES_PATCH_STATE,
    payload: { loading: false },
  });
};
