import React, { useState, useEffect } from "react";
import { spacedOutTextStyle } from "./spacedOutTextStyle";
import { renderFeatures } from "./Features";
import { renderTimetables } from "./Timetables";
import ExperienceMap from "./ExperienceMap";
import ExperienceGallery from "./ExperienceGallery";
import RelatedExperiences from "./RelatedExperiences";
import { FaPhone, FaEnvelope, FaGlobe, FaFacebookF, FaInstagram, FaWhatsapp } from "react-icons/fa";

const ExperienceContent = ({ experience, loading }) => {
  const [showMap, setShowMap] = useState(false);

  useEffect(() => {
    if (!loading) {
      const timer = setTimeout(() => setShowMap(true), 100);
      return () => clearTimeout(timer);
    }
  }, [loading]);

  if (loading) {
    return <div>Loading experience...</div>;
  }

  return (
    <>
      <div
        style={{
          display: "flex",
          marginTop: "40px",
          gap: "40px",
        }}
      >
        <div
          style={{
            width: "100%",
            height: "100%",
          }}
        >
          <ExperienceGallery images={experience.images} />
        </div>
      </div>

      <div
        style={{
          display: "flex",
          marginTop: "40px",
          gap: "40px",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "100px",
            width: "72%",
          }}
        >
          <div style={{ marginTop: "20px" }}>{renderFeatures(experience)}</div>
          <Description experience={experience} />
          {showMap && <ExperienceMap lat={experience.latitude} lng={experience.longitude} />}
          <RelatedExperiences experience={experience} loading={loading} />
        </div>

        <div style={{ width: "100%" }}>
          <div
            style={{
              position: "sticky",
              top: "200px",
              display: "flex",
              flexDirection: "column",
              gap: "50px",
            }}
          >
            <ContactInfo experience={experience} />
            <div>{renderTimetables(experience)}</div>
          </div>
        </div>
      </div>
    </>
  );
};

const ContactInfo = ({ experience }) => {
  const ContactInfo = experience.contact;
  const maxLength = 20; // Maximum length before truncating

  const truncateText = (text) => {
    if (!text) return "";
    return text.length > maxLength ? text.substring(0, maxLength) + "..." : text;
  };

  const allValuesEmpty = Object.values(ContactInfo).every((value) => value === null || value === undefined);

  return !allValuesEmpty ? (
    <div
      style={{
        border: "2px solid var(--principal-color-page)",
        borderRadius: "8px",
        padding: "20px",
        maxWidth: "100%",
        ...spacedOutTextStyle,
      }}
    >
      <h3
        style={{
          fontWeight: 600,
          marginBottom: "20px",
          textAlign: "center",
          ...spacedOutTextStyle,
        }}
      >
        Contact
      </h3>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          marginBottom: "15px",
          marginLeft: "10px",
          fontSize: "12px",
          ...spacedOutTextStyle,
        }}
      >
        {ContactInfo.phone_number ? (
          <p style={{ display: "flex" }}>
            <FaPhone style={{ marginRight: "15px", fontSize: "20px" }} />
            <a href={`tel:${ContactInfo.phone_number}`}>{truncateText(ContactInfo.phone_number)}</a>
          </p>
        ) : (
          <></>
        )}
      </div>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          marginBottom: "15px",
          marginLeft: "10px",
          fontSize: "12px",
          ...spacedOutTextStyle,
        }}
      >
        {ContactInfo.email ? (
          <p style={{ display: "flex" }}>
            <FaEnvelope style={{ marginRight: "15px", fontSize: "20px" }} />
            <a href={`mailto:${ContactInfo.email}`}>{truncateText(ContactInfo.email)}</a>
          </p>
        ) : (
          <></>
        )}
      </div>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          marginBottom: "15px",
          marginLeft: "10px",
          fontSize: "12px",
          ...spacedOutTextStyle,
        }}
      >
        {ContactInfo.website_url ? (
          <p style={{ display: "flex" }}>
            <FaGlobe style={{ marginRight: "15px", fontSize: "20px" }} />
            <a href={ContactInfo.website_url} target="_blank" rel="noopener noreferrer">
              {truncateText(ContactInfo.website_url)}
            </a>
          </p>
        ) : (
          <></>
        )}
      </div>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          marginBottom: "15px",
          marginLeft: "10px",
          fontSize: "12px",
          ...spacedOutTextStyle,
        }}
      >
        {ContactInfo.facebook_url ? (
          <p style={{ display: "flex" }}>
            <FaFacebookF style={{ marginRight: "15px", fontSize: "20px" }} />
            <a href={ContactInfo.facebook_url} target="_blank" rel="noopener noreferrer">
              Facebook Page
            </a>
          </p>
        ) : (
          <></>
        )}
      </div>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          marginBottom: "15px",
          marginLeft: "10px",
          fontSize: "12px",
          ...spacedOutTextStyle,
        }}
      >
        {ContactInfo.instagram_url ? (
          <p style={{ display: "flex" }}>
            <FaInstagram style={{ marginRight: "15px", fontSize: "20px" }} />
            <a href={ContactInfo.instagram_url} target="_blank" rel="noopener noreferrer">
              Instagram Profile
            </a>
          </p>
        ) : (
          <></>
        )}
      </div>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          marginLeft: "10px",
          fontSize: "12px",
          ...spacedOutTextStyle,
        }}
      >
        {ContactInfo.whats_app ? (
          <p style={{ display: "flex" }}>
            <FaWhatsapp style={{ marginRight: "15px", fontSize: "20px" }} />
            <a href={`https://wa.me/${ContactInfo.whats_app}`} target="_blank" rel="noopener noreferrer">
              {truncateText(ContactInfo.whats_app)}
            </a>
          </p>
        ) : (
          <></>
        )}
      </div>
    </div>
  ) : (
    <></>
  );
};

const Description = ({ experience }) => (
  <div>
    <h2
      style={{
        fontSize: "28px",
        fontWeight: 600,
        ...spacedOutTextStyle,
      }}
    >
      Description
    </h2>
    <p style={{ ...spacedOutTextStyle }}>{experience.description || "Description not available"}</p>
  </div>
);

export default ExperienceContent;
