import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./Slick.css";

const Slick = (props) => {
  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: props.slidesToShow,
    slidesToScroll: props.slidesToScroll,
    arrows: props.arrows,
    initialSlide: 0,
  };
  return <Slider {...settings}>{props.children}</Slider>;
};
export default Slick;
